import CognitoLogIn from 'components/cognito-login';
import { AuthContext } from 'hooks/useAuth';
import React, { Suspense, useContext } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import {
  adminRoles,
  checkIfAdmin,
  checkIfCompanyViewer,
  companyViewerRoles,
  factoryViewerRoles
} from 'utils';

import insightsConfig from '../../config/config';
import NotFound from '../../pages/error/not-found';
import ExpiredSignInAgain from '../../pages/expired_sign_out';
import HelpSupport from '../../pages/help_support';
import Profile from '../../pages/profile';
import SignInAgain from '../../pages/sign_out';
import RedirectToActualUrl from '../../pages/urlredirection/url-redirection';
import CognitoLogOut from '../cognito-logout';
import Loading from '../loading';
import ProtectedRoute from './ProtectedRoute';

const Production = React.lazy(() => import('../../pages/production'));
const Quality = React.lazy(() => import('../../pages/quality'));
const Jobs = React.lazy(() => import('../../pages/jobs'));
const MultipleInspections = React.lazy(() => import('../../pages/multiple-insp/multiple-insp'));
const Inspection = React.lazy(() => import('../../pages/inspection/inspection'));
const MachineHealth = React.lazy(() => import('../../pages/machine_health/machine-health'));
const MachineMonitoring = React.lazy(() => import('../../pages/monitoring'));
const ProductConfig = React.lazy(() => import('../../pages/productConfig/product-config'));
const ExportData = React.lazy(() => import('../../pages/export/export-data'));
const InspectionDetails = React.lazy(() => import('../../pages/inspection/inspectionDetails'));
const Admin = React.lazy(() => import('../../pages/admin'));
const WeightsDashboard = React.lazy(() => import('../../pages/weights'));

interface FeatureRoute {
  path: string;
  component: any;
}

const featureRoutes: FeatureRoute[] = [
  { path: '/jobs', component: Jobs },
  { path: '/multiple-insp', component: MultipleInspections },
  { path: '/machinehealth', component: MachineHealth },
  { path: '/machine-monitoring', component: MachineMonitoring },
  { path: '/productconfiguration', component: ProductConfig },
  { path: '/exportdata', component: ExportData },
  { path: '/inspections', component: Inspection },
  { path: '/shared_url/:sharedUrlId', component: RedirectToActualUrl }
];

const companyRoutes: FeatureRoute[] = [
  { path: '/production/production_company_view', component: Production },
  { path: '/quality/quality_company_view', component: Quality },
  { path: '/weights/weights_company_view', component: WeightsDashboard }
];

const userRoutes: FeatureRoute[] = [
  { path: '/production/production_location_view', component: Production },
  { path: '/production/production_line_view', component: Production },
  { path: '/quality/quality_location_view', component: Quality },
  { path: '/quality/quality_line_view', component: Quality },
  ...(insightsConfig.featureToggles.isEnableWeightsDashboard
    ? [
        { path: '/weights/weights_location_view', component: WeightsDashboard },
        { path: '/weights/weights_line_view', component: WeightsDashboard }
      ]
    : [])
];

export const RoutesConfig: React.FC = () => {
  const { authDetails } = useContext(AuthContext);
  const { roleDetails } = authDetails || {};
  const restrictedAccessUser = !roleDetails?.session_id;
  const isAdmin = checkIfAdmin(roleDetails?.role || '');
  const isCompanyViewer = checkIfCompanyViewer(roleDetails?.role || '');

  return (
    <Suspense fallback={<Loading />}>
      <Routes>
        {!restrictedAccessUser ? (
          <>
            {featureRoutes.map(({ path, component }) => (
              <Route
                key={path}
                path={path}
                element={
                  <ProtectedRoute
                    allowedRoles={[...adminRoles, ...companyViewerRoles, ...factoryViewerRoles]}
                    element={React.createElement(component)}
                  />
                }
              >
                {path === '/inspections' && (
                  <Route
                    path=":inspectionId"
                    element={
                      <ProtectedRoute
                        allowedRoles={[...adminRoles, ...companyViewerRoles, ...factoryViewerRoles]}
                        element={<InspectionDetails />}
                      />
                    }
                  />
                )}
              </Route>
            ))}
            {!insightsConfig.featureToggles.isEnableMultipleTabs ? (
              <>
                <Route path="/gsaiLogin" element={<Navigate replace to="/" />} />
                <Route
                  path="/"
                  element={
                    <ProtectedRoute
                      allowedRoles={[...adminRoles, ...companyViewerRoles, ...factoryViewerRoles]}
                      element={<Production />}
                    />
                  }
                />
                <Route
                  path="/quality"
                  element={
                    <ProtectedRoute
                      allowedRoles={[...adminRoles, ...companyViewerRoles, ...factoryViewerRoles]}
                      element={<Quality />}
                    />
                  }
                />
                <Route
                  path="/weights"
                  element={
                    <ProtectedRoute
                      allowedRoles={[...adminRoles, ...companyViewerRoles, ...factoryViewerRoles]}
                      element={<WeightsDashboard />}
                    />
                  }
                />
              </>
            ) : (
              <>
                {companyRoutes.map(
                  ({ path, component }) =>
                    isCompanyViewer && (
                      <Route
                        key={path}
                        path={path}
                        element={
                          <ProtectedRoute
                            allowedRoles={[...adminRoles, ...companyViewerRoles]}
                            element={React.createElement(component)}
                          />
                        }
                      />
                    )
                )}
                {userRoutes.map(({ path, component }) => (
                  <Route
                    key={path}
                    path={path}
                    element={
                      <ProtectedRoute
                        allowedRoles={[...adminRoles, ...companyViewerRoles, ...factoryViewerRoles]}
                        element={React.createElement(component)}
                      />
                    }
                  />
                ))}
                <Route
                  path="/"
                  element={
                    <Navigate
                      replace
                      to={
                        isCompanyViewer
                          ? '/production/production_company_view'
                          : '/production/production_location_view'
                      }
                    />
                  }
                />
                <Route
                  path="/gsaiLogin"
                  element={
                    <Navigate
                      replace
                      to={
                        isCompanyViewer
                          ? '/production/production_company_view'
                          : '/production/production_location_view'
                      }
                    />
                  }
                />
              </>
            )}
            {isAdmin && (
              <Route
                path="/ManageUsers"
                element={<ProtectedRoute allowedRoles={adminRoles} element={<Admin />} />}
              />
            )}
          </>
        ) : (
          <Route
            path="/"
            element={
              <NotFound headerText="Access Restricted">
                Please contact your administrator for access and reload the page.
              </NotFound>
            }
          />
        )}
        <Route path="/profile" element={<Profile />} />
        <Route path="/signOut" element={<SignInAgain />} />
        <Route path="/help" element={<HelpSupport />} />
        <Route path="/404" element={<NotFound />} />
        <Route path="/logOut" element={<CognitoLogOut />} />
        <Route path="/sessionExpired" element={<ExpiredSignInAgain />} />
        <Route
          path="*"
          element={
            <NotFound headerText="URL Not Found" showAddress={false}>
              {' '}
            </NotFound>
          }
        />
      </Routes>
    </Suspense>
  );
};

export const LoginRoutes: React.FC = () => {
  const { isCognitoHostedUI, identityProvider } = insightsConfig.amplify;
  return (
    <Routes>
      <Route path="/logOut" element={<CognitoLogOut />} />
      <Route
        path="/gsaiLogin"
        element={
          <CognitoLogIn identityProvider={'GsaiAzureAD'} isCognitoHostedUI={isCognitoHostedUI} />
        }
      />
      <Route
        path="*"
        element={
          <CognitoLogIn identityProvider={identityProvider} isCognitoHostedUI={isCognitoHostedUI} />
        }
      />
    </Routes>
  );
};
