import { Auth } from 'aws-amplify';
import axios from 'axios';
import insightsConfig from 'config/config';

let authDetails: IAuthDetails;

interface IAuthDetails {
  userSession: {
    getIdToken: () => { getJwtToken: () => void };
  };
}

function updateAuthDetails(authDetailsParam: IAuthDetails) {
  if (
    authDetails?.userSession?.getIdToken().getJwtToken() !==
    authDetailsParam?.userSession?.getIdToken().getJwtToken()
  ) {
    authDetails = authDetailsParam;
  }
}

const tokenizedAxios = axios.create({
  baseURL: insightsConfig.baseURL.inspectionAPI,
  responseType: 'json',
  transformResponse: [
    function (data: any) {
      // Do whatever you want to transform the data
      //   console.log("data ", data);
      return data;
    }
  ]
});

// Add a request Interceptor
tokenizedAxios.interceptors.request.use(async function (config: any) {
  const userData = await Auth.currentAuthenticatedUser();
  const userSession = userData.getSignInUserSession();
  config.headers.CognitoAuthorization = userSession.getIdToken().getJwtToken();
  return config;
}, undefined);

const onFulfilled = (response: any) => JSON.parse(response.data);

// Response Interceptor to handle token expiration
tokenizedAxios.interceptors.response.use(onFulfilled, async function (error) {
  // Check if the error is due to token expiration and if this is the first retry attempt
  if (error?.response?.status === 401) {
    window.location.href = '/sessionExpired'; // Redirect to login or another specified page if refresh fails
    return Promise.reject(error);
  }
  return Promise.reject(error);
});

export default tokenizedAxios;
export { updateAuthDetails };
