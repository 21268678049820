import { CognitoUser } from 'amazon-cognito-identity-js';
import { Auth, Hub } from 'aws-amplify';
import { createContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getEmailId } from 'utils';

import insightsConfig, { TuiConfigs, uiConfigs } from '../config/config';
import RoleService from './RoleService';

const MAX_RETRY = 3;
const RETRY_DELAY = 5000;

interface RoleDetails {
  name?: string;
  email_id?: string;
  role?: string;
  factory_access?: string;
  status?: string;
  last_login?: string;
  session_id?: string;
  session_created_time?: string;
  session_expiry_time?: string;
  help_info?: { name: string; email_id: string }[];
}

interface AuthDetails {
  isLoading: boolean;
  userSession: any;
  uiConfigs: TuiConfigs;
  roleDetails?: RoleDetails;
}

const initialState: AuthDetails = {
  isLoading: true,
  userSession: null,
  uiConfigs: uiConfigs
};

interface IAuthContext {
  authDetails: AuthDetails;
  handleAuth: ({ payload }: { payload: any }) => void;
  updateUiConfigFnc: ({ payload }: { payload: any }) => void;
  refreshTokenIfNeeded: () => Promise<void>;
  refreshSessionIfNeeded: () => Promise<AuthDetails>;
}

interface IUpdateUiConfigFnc {
  payload: {
    data: Record<string, any>;
    event: string;
  };
}

const AuthContext = createContext<IAuthContext>({
  authDetails: initialState,
  handleAuth: () => {
    // intentionally left empty
  },
  // eslint-disable-next-line no-empty-pattern
  updateUiConfigFnc: ({}: IUpdateUiConfigFnc) => {
    // intentionally left empty
  },
  refreshTokenIfNeeded: () => Promise.resolve(),
  refreshSessionIfNeeded: () => Promise.resolve(initialState)
});

const getAddress = (address: any) => {
  if (!address) return;
  return decodeURIComponent(address.slice(1, -1).replace(/\+/g, ' '))
    .split(',')
    .map((item) => item.trim());
};

export function useAuth() {
  const [authDetails, setAuthDetails] = useState<AuthDetails>(initialState);
  const [isRefreshingToken, setIsRefreshingToken] = useState(false);
  const navigate = useNavigate();
  const roleService = useMemo(() => RoleService.getInstance(), []);

  const getRoleDetails = async (
    session: any,
    isRefresh = false
  ): Promise<RoleDetails | undefined> => {
    try {
      const token = session.getIdToken();
      const email_id = getEmailId(token);
      return await roleService.fetchRole(
        email_id,
        `${token.payload.given_name} ${token.payload.family_name}`,
        getAddress(token.payload?.nickname) || [],
        token.getJwtToken(),
        token.payload.auth_time,
        isRefresh
      );
    } catch (error) {
      console.error('Error fetching role:', error);
      return undefined;
    }
  };

  const handleAuth = ({ payload }: { payload: any }) => {
    switch (payload.event) {
      case 'signIn':
      case 'cognitoHostedUI':
        setAuthDetails((prev) => ({
          ...prev,
          isLoading: true,
          userSession: payload.data.getSignInUserSession()
        }));
        break;
      case 'signInFailure':
      case 'tokenRefresh_failure':
      case 'signOut':
        setAuthDetails((prev) => ({
          ...prev,
          isLoading: false,
          userSession: null
        }));
        break;
      default:
        break;
    }
  };

  const updateUiConfigFnc = ({ payload }: { payload: any }) => {
    if (payload.event === 'updateUiConfig') {
      setAuthDetails((prev) => ({
        ...prev,
        uiConfigs: { ...prev.uiConfigs, ...payload.data }
      }));
    }
  };

  const getUser = async () => {
    try {
      const userData = await Auth.currentAuthenticatedUser();
      const userSession = userData.getSignInUserSession();
      const roleDetails = userSession ? await getRoleDetails(userSession) : undefined;

      setAuthDetails((prev) => ({
        ...prev,
        isLoading: false,
        userSession: userSession,
        roleDetails: roleDetails
      }));
    } catch (error) {
      console.log('Not signed in');
      setAuthDetails((prev) => ({
        ...prev,
        isLoading: false,
        userSession: null
      }));
    }
  };

  const refreshToken = async (cognitoUser: CognitoUser, retryCount = 0): Promise<void> => {
    try {
      const currentSession = await Auth.currentSession();
      return new Promise<void>((resolve, reject) => {
        cognitoUser.refreshSession(currentSession.getRefreshToken(), async (err, session) => {
          setIsRefreshingToken(false);

          if (err) {
            console.error('Error refreshing token: ', err);
            if (retryCount < MAX_RETRY) {
              setTimeout(
                () =>
                  refreshToken(cognitoUser, retryCount + 1)
                    .then(resolve)
                    .catch(reject),
                RETRY_DELAY
              );
            } else {
              console.error('Max retries reached. Unable to refresh token.');
              handleSessionExpiry(err);
              reject();
            }
          } else {
            const roleDetails = await getRoleDetails(session, true);
            setAuthDetails((prev) => ({
              ...prev,
              isLoading: false,
              userSession: session,
              roleDetails
            }));
            resolve();
          }
        });
      });
    } catch (error) {
      console.error('Error refreshing token: ', error);
    }
  };

  const refreshSessionIfNeeded = async (): Promise<AuthDetails> => {
    try {
      if (authDetails.userSession && !authDetails.roleDetails) {
        const roleDetails = await getRoleDetails(authDetails.userSession);
        setAuthDetails((prev) => ({ ...prev, roleDetails }));
      } else if (authDetails?.roleDetails?.session_expiry_time) {
        const expiryTime = new Date(authDetails.roleDetails.session_expiry_time).getTime();
        const timeDiff = expiryTime - Date.now();

        if (timeDiff < 3000000) {
          const roleDetails = await getRoleDetails(authDetails.userSession, true);
          const updatedAuthDetails = {
            ...authDetails,
            roleDetails: roleDetails
          };
          setAuthDetails(updatedAuthDetails);
          return updatedAuthDetails;
        }
      }
      return authDetails;
    } catch (error) {
      console.error('Error refreshing session: ', error);
      return authDetails;
    }
  };

  const refreshTokenIfNeeded = async () => {
    try {
      const currentSession = await Auth.currentSession();
      const expiresIn = currentSession.getIdToken().getExpiration() - Math.floor(Date.now() / 1000);

      if (expiresIn < 300) {
        setIsRefreshingToken(true);
        const cognitoUser = await Auth.currentAuthenticatedUser();
        await refreshToken(cognitoUser);
      } else if (
        currentSession &&
        authDetails.userSession &&
        currentSession.getIdToken()?.getJwtToken() !==
          authDetails.userSession?.getIdToken().getJwtToken()
      ) {
        setAuthDetails((prev) => ({
          ...prev,
          isLoading: false,
          userSession: currentSession
        }));
      }
    } catch (error) {
      console.error('Error checking token expiration: ', error);
    }
  };

  const handleSessionExpiry = (err: any) => {
    console.log('Session expired. Redirecting to login page...');
    navigate('sessionExpired');
    setAuthDetails((prev) => ({
      ...prev,
      isLoading: false,
      userSession: null
    }));
    handleAuth({ payload: { event: 'tokenRefresh_failure', data: err } });
  };

  const handleVisibilityChange = () => {
    if (document.visibilityState === 'visible') {
      refreshTokenIfNeeded();
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      refreshTokenIfNeeded();
      refreshSessionIfNeeded();
    }, insightsConfig.amplify.refreshTokenInterval * 60000);

    Hub.listen('auth', handleAuth);
    getUser();
    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      clearInterval(intervalId);
      Hub.remove('auth', handleAuth);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  return {
    authDetails,
    handleAuth,
    updateUiConfigFnc,
    refreshTokenIfNeeded,
    isRefreshingToken,
    refreshSessionIfNeeded
  };
}

export { AuthContext };
